/* eslint-disable react/jsx-pascal-case */
import { Container, useMediaQuery, useTheme } from "@mui/material";
import { stringStr } from "../../constant/String";

import UseCases from "../UseCases/UseCases";
import EventTimeLine from "../EventDetails/EventTimeline";
import Prizes from "../Prizes/Prizes";
import JudgesAndWinningCriteria from "../jusdgesandwinningCriteria/JudgesAndWinningCriteria";
import HomeBanner from "../comman/HomeBanner";
import BottamCard from "../comman/BottomCard";
import HomeLocDateEligibiltyPrizes from "./HomeLocDateEligibilityPrizes";
import UsePageBottam from "../../Hooks/UsePageBottam";
import { ScrollUpArrow } from "../comman/ScrollUpArrow";

const Home = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const showGoTop = UsePageBottam();

  const paddings = isSmallScreen ? "10px" : "15px";
  return (
    <Container
      maxWidth="lg"
      sx={{ margin: "0 auto", padding: isSmallScreen ? "10px" : "20px" }}
    >
      <HomeBanner />
      <HomeLocDateEligibiltyPrizes
        isSmallScreen={isSmallScreen}
        padding={paddings}
      />
      <UseCases />

      <EventTimeLine />
      <Prizes />
      <JudgesAndWinningCriteria />
      <BottamCard
        buttonLabel={stringStr.registerNow}
        title1={stringStr.ListeningCardHome.ListeningCardTitle1}
        title2={stringStr.ListeningCardHome.ListeningCardTitle2}
        subTitle={stringStr.ListeningCardHome.ListeningCardSubTitle}
      />
      {showGoTop && <ScrollUpArrow />}
    </Container>
  );
};
export default Home;

import { Box, CardContent, Grid, Typography } from "@mui/material";

import EventCardContent from "./EventCardContent";
const EventBoxes = ({ title, img1, img2, img3, text1, text2, text3 }) => {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#ededed",
        borderRadius: "10px",
        border: "1px solid #eea08a",
        justifyContent: "center",
        margin: 1,
      }}
    >
      <Typography
        variant="h4"
        sx={{
          color: "#030303",
          fontSize: { xs: "1.5rem", md: "2rem" },
          fontFamily: "Poppins",
          fontWeight: 400,
          lineHeight: "62px",
          textAlign: "center",
        }}
      >
        {title}
      </Typography>
      <EventCardContent
        space={img1}
        microscope={img2}
        astronut={img3}
        text1={text1}
        text2={text2}
        text3={text3}
      />
    </Box>
  );
};
export default EventBoxes;

import axios from "axios";
const baseURL =
  "https://3jw6anbrf2.execute-api.ap-south-1.amazonaws.com/dev/registration";
const instance = axios.create({
  headers: {
    "x-api-key": "1nFaTmkLIuaM9uIqtJzpL4TtkRmQJovy3Eow7TIb",
  },
});

export const TeamSignup = async (formData) => {
  try {
    const response = await instance.post(baseURL, formData);
    const data = await response.data;
    return data;
  } catch (error) {}
};

export const FAQContent = {
  Title: "Frequently Asked Questions",
  HeadingTitle: "Got questions? we'd love to answer",
};

export const Questions = [
  {
    Question: "1. What is DigiHack?",
    Answer: {
      ans: "DigiHack is Max Life Insurance's internal hackathon, organized in collaboration with AWS, aimed at fostering innovation in digital technology, AI, and IT. Employees will come together to brainstorm, create, and develop solutions that can drive the future of the insurance industry.",
    },
  },
  {
    Question: "2. Who can participate in DigiHack?",
    Answer: {
      ans: "Employees from Max Life Insurance (DigiTech, AI works) and Development Partner Teams (Infosys, TCS, Monocept, TTN, Blazeclan, Kellton, etc.) are eligible to participate. Teams should consist of a maximum of 5 people.",
    },
  },

  {
    Question: "3. How do I register for DigiHack?",
    Answer: {
      ans: "You can register for DigiHack through a dedicated microsite created for the event. The registration period is from August 5th to August 16th.",
    },
  },

  {
    Question: "4. What are the event dates and timeline?",
    Answer: [
      { ans: "Registrations of Teams: August 5th–16th" },
      { ans: "Orientation by Innovation Team: September 7th" },
      { ans: "AWS Workshops: September 7th" },
      { ans: "PR FAQ Submission: September 8th" },
      { ans: "AWS Accounts Provided to Teams: September 10th-11th" },
      { ans: "Hackathon Day 1: September 14th" },
      { ans: "Hackathon Day 2 and Evaluation: September 15th" },
      {
        AnsBottonText: "Refer to the Event Details page for more information.",
      },
    ],
  },

  {
    Question: "5. Who should be on my Team?",
    Answer: [
      { AnsTitle: "Each team should ideally consist of or have skills with" },
      { ans: "Product/Project Manager" },
      { ans: "Solution Architect" },
      { ans: "Front-end Developer" },
      { ans: "Back-end Developer" },
      { ans: "Data/DevOps Expert or Open Role" },
    ],
  },
  {
    Question: "6. What are the proposed use cases?",
    Answer: [
      {
        AnsTitle:
          "Each team gets to choose any 1 use case from the following to build their solution:",
      },
      { ans: "Creating a better value proposition/tech stack for Gen Z" },
      {
        ans: "Increasing insurance value proposition for High Net Worth Individuals",
      },
      { ans: "Engaging tech journey to boost rural business" },
      { ans: "Designing a Financial Advisory tool" },
      {
        ans: "End-to-end WhatsApp journey to reduce Customer Acquisition cost",
      },
      {
        ans: "Non-inclusive Underwriting ecosystem to reduce Policy issuance TAT",
      },
      { ans: "Smarter embedded journeys to increase Group business sales" },
      {
        ans: "Leveraging alternate forms of agency to increase insurance sales",
      },
      {
        ans: "Intelligent insurance journeys/tech offering for retirement business",
      },
      { ans: "Leveraging Generative AI for Insurance solutions" },
      { ans: "Digital Branch Offering to cater to all branch users" },
      { AnsBottonText: "Refere to the Use Cases page for more information." },
    ],
  },

  {
    Question: "7. What resources will be provided during the hackathon?",
    Answer: {
      ans: "Participants will have access to necessary tools, software, and mentorship from industry experts and internal business teams. AWS accounts will be provided to teams, and specific workshops and training sessions will be conducted by AWS & the Innovation team to prepare participants for the event.",
    },
  },
  {
    Question: "8. Are there prizes for winning teams?",
    Answer: [
      {
        AnsTitle:
          "Yes, there are exciting prizes for the top-performing teams:",
      },
      { ans: "Winner: ₹25,000" },
      { ans: "Runner-Up: ₹15,000" },
      { ans: "Second Runner-Up: ₹10,000" },
      {
        AnsBottonText:
          "Additionally, all participants will receive SWAG kits, and there will be trophies for winners and certificates for all participants.",
      },
    ],
  },
  {
    Question: "9. How will the projects be judged?",
    Answer: [
      { AnsTitle: "Projects will be evaluated based on:" },
      { ans: "Is it a Working Prototype?" },
      { ans: "Does it Solve the Business Problem?" },
      { ans: "Is it Practical and Feasible to Deploy?" },
      {
        ans: "How good is the solution quality? (Novelty of Idea, Detail of Solution)",
      },
    ],
  },
  {
    Question: "10. Can I continue working on my project after the hackathon?",
    Answer: {
      ans: "Yes, participants are encouraged to continue refining and developing their projects. Promising solutions may receive further support and funding for implementation within the company.",
    },
  },
  {
    Question: "11. What if I need help during the hackathon?",
    Answer: {
      ans: "Mentors and support staff, including the AWS Team, Innovation Team, Business Team SPOCs, Enterprise Architecture team, and Legal, Compliance & Info-sec teams, will be available throughout the event to assist you with any technical or non-technical challenges you may face.",
    },
  },

  {
    Question: "12. What are the rules of the hackathon?",
    Answer: [
      { AnsTitle: "Rules are simple" },
      { ans: "Always adhere to timelines of submissions" },
      { ans: "Choose only one use case per team" },
      { ans: "Have fun and be creative" },
    ],
  },

  {
    Question: "13. Whom should I contact for more information?",
    Answer: {
      ans: "For any queries or additional information, please contact the DigiHack organizing team at [Contact Email] or [Contact Number].",
    },
  },
];

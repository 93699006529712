import { Alert, Snackbar } from "@mui/material";
import { stringStr } from "../../constant/String";

const SnackbarMessage = (props) => (
  <Snackbar
    open={props.open}
    autoHideDuration={6000}
    onClose={props.handleClose}
    action={props.action}
  >
    <Alert
      onClose={props.handleClose}
      severity="success"
      variant="filled"
      sx={{
        width: "100%",
      }}
    >
      {stringStr.formLabel.snackbarMessage}
    </Alert>
  </Snackbar>
);
export default SnackbarMessage;

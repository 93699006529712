import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { stringStr } from "../../constant/String";
import EventTimeLine from "./EventTimeline";
import aboutEventImg from "../../assets/aboutevent.png";
import Prizes from "../Prizes/Prizes";
import JudgesAndWinningCriteria from "../jusdgesandwinningCriteria/JudgesAndWinningCriteria";
import space from "../../assets/space.png";
import microscope from "../../assets/microscope.png";
import astronut from "../../assets/astronut.png";
import EventBoxes from "./EventBoxes";
import EventMethodology from "./EventMethodology";
import { TeamSection } from "../comman/TeamSection";
import BottamCard from "../comman/BottomCard";
import { MentorshipTeam } from "../../constant/MentorshipTeam";
import CustomeImage from "../comman/CustomeImage";
import UsePageBottam from "../../Hooks/UsePageBottam";

import { ScrollUpArrow } from "../comman/ScrollUpArrow";

const AboutTheEvent = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const showGoTop = UsePageBottam();

  return (
    <Container
      maxWidth="lg"
      sx={{ margin: "0 auto", padding: isSmallScreen ? "10px" : "20px" }}
    >
      <Box
        sx={{
          margin: "auto",
          width: { xs: "80%", md: "50%" },
          marginTop: "40px",
          padding: "10px",
          textAlign: "center",
        }}
      >
        <Typography variant="h3">{stringStr.AboutEventTitle}</Typography>
        <Typography variant="subtitle1">
          {stringStr.ABoutEventSubTitle}
        </Typography>
        <CustomeImage src={aboutEventImg} alt={"Event Details"} />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "center",
          height: "auto",
          padding: 4,
        }}
      >
        <EventBoxes
          title={stringStr.whyParticipate.title}
          img1={space}
          img2={microscope}
          img3={astronut}
          text1={stringStr.whyParticipate.text1}
          text2={stringStr.whyParticipate.text2}
          text3={stringStr.whyParticipate.text3}
        />
        <EventBoxes
          title={stringStr.whoParticipate.title}
          img1={space}
          img2={microscope}
          text1={stringStr.whoParticipate.text1}
          text2={stringStr.whoParticipate.text2}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "center",
          paddingLeft: 4,
          paddingRight: 4,
        }}
      >
        <EventBoxes
          title={stringStr.whatAreTheRule.title}
          img1={space}
          img2={microscope}
          img3={astronut}
          text1={stringStr.whatAreTheRule.text1}
          text2={stringStr.whatAreTheRule.text2}
          text3={stringStr.whatAreTheRule.text3}
        />
        <EventBoxes
          title={stringStr.HowDoIWin.title}
          img1={space}
          img2={microscope}
          img3={astronut}
          text1={stringStr.HowDoIWin.text1}
          text2={stringStr.HowDoIWin.text2}
          text3={stringStr.HowDoIWin.text3}
        />
      </Box>

      <EventTimeLine />
      <EventMethodology />
      <Prizes />
      <JudgesAndWinningCriteria />

      <Box sx={{ marginTop: 6, textAlign: "center" }}>
        <Typography
          sx={{
            color: "#030303",
            fontSize: { xs: "24px", md: "35px" },
            fontFamily: "Poppins",
            fontWeight: 600,
            lineHeight: { xs: "30px", md: "62px" },
          }}
        >
          {MentorshipTeam.TitleOfSection}
        </Typography>
        <Typography
          sx={{
            color: "#030303",
            fontSize: "15px",
            fontFamily: "Inter",
            lineHeight: "26px",
          }}
          variant="subtitle2"
        >
          {MentorshipTeam.TitleOfSectionSubTitle}
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 5,
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <TeamSection
            title={MentorshipTeam.InnovationTeam.Title}
            members={[
              MentorshipTeam.InnovationTeam.name1,
              MentorshipTeam.InnovationTeam.name2,
              MentorshipTeam.InnovationTeam.name3,
              MentorshipTeam.InnovationTeam.name4,
            ]}
          />
          <TeamSection
            title={MentorshipTeam.AWSTeam.Title}
            members={[
              MentorshipTeam.AWSTeam.name1,
              MentorshipTeam.AWSTeam.name2,
              MentorshipTeam.AWSTeam.name3,
            ]}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 5,
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <TeamSection
            title={MentorshipTeam.BusinessSPOCs.Title}
            members={[
              MentorshipTeam.BusinessSPOCs.name1,
              MentorshipTeam.BusinessSPOCs.name2,
              MentorshipTeam.BusinessSPOCs.name3,
              MentorshipTeam.BusinessSPOCs.name4,
            ]}
          />
          <TeamSection
            title={MentorshipTeam.SupportSPOCs.Title}
            members={[
              MentorshipTeam.SupportSPOCs.name1,
              MentorshipTeam.SupportSPOCs.name2,
              MentorshipTeam.SupportSPOCs.name3,
            ]}
          />
        </Box>
      </Box>

      <BottamCard
        title1={stringStr.ListeningCardEventDetails.ListeningCardTitle1}
        title2={stringStr.ListeningCardEventDetails.ListeningCardTitle2}
        subTitle={stringStr.ListeningCardEventDetails.ListeningCardSubTitle}
        buttonLabel={stringStr.registerNow}
      />
      {showGoTop && <ScrollUpArrow />}
    </Container>
  );
};

export default AboutTheEvent;

import { useState } from "react";
import { Validation } from "../component/Service/Validation";
import { useNavigate } from "react-router-dom";
import { TeamSignup } from "../component/Service/ApiMethods";

const useRegistrationform = (initailState) => {
  const [formData, setFormData] = useState(initailState);
  const [touched, setTouched] = useState({
    TeamName: false,
    Usecase: false,
    Members: initailState.Members.map(() => ({})),
  });
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({
    TeamName: "",
    Usecase: "",
    Members: initailState.Members.map(() => ({})),
  });

  const [serverError, setServerError] = useState({});
  const [loader, setLoader] = useState(true);

  const navigate = useNavigate();
  const handleTeamChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleMemberChange = (index, field, value) => {
    const updateMemnbers = formData.Members.map((member, i) =>
      i === index ? { ...member, [field]: value } : member
    );
    setFormData((prevData) => ({ ...prevData, Members: updateMemnbers }));
  };

  const onsubmitHandler = async (e) => {
    e.preventDefault();

    const error = Validation(formData);

    error.Members = error.Members.map((member, index) =>
      index >= 3 && !member ? {} : member
    );

    setErrors(error);
    if (
      Object.keys(error).length === 0 ||
      (Object.keys(error).length === 1 &&
        error.Members &&
        error.Members.every(
          (member) => member && Object.keys(member).length === 0
        ))
    ) {
      setLoader(false);
      const data = { data: { ...formData } };

      const response = await TeamSignup(data);

      if (response.status === "Success") {
        setOpen(true);
        setServerError(null);
        const timer = setTimeout(() => {
          navigate("/thankyou", { state: formData });
        }, 4000);
        return () => {
          clearTimeout(timer);
        };
      } else {
        const serverErrorObj = {};
        if (
          response.description ===
          "Team name is already Taken. Please provide another Team name"
        ) {
          serverErrorObj.TeamName = response.description;
        } else {
          serverErrorObj.MemberEmail = response.description;
        }
        setServerError(serverErrorObj);
      }
    }
    setLoader(true);
  };

  const onHandlerBlur = (field) => {
    const [mainField, index, subField] = field.split(/[[\].]+/).filter(Boolean);
    if (mainField === "Members" && subField) {
      const memberIndex = parseInt(index, 10);
      const newTouched = { ...touched };
      if (!newTouched.Members[memberIndex]) {
        newTouched.Members[memberIndex] = {};
      }
      newTouched.Members[memberIndex][subField] = true;
      setTouched(newTouched);
    } else {
      setTouched({
        ...touched,
        [field]: true,
      });
    }
  };
  return {
    formData,
    touched,
    serverError,
    errors,
    open,
    loader,
    setTouched,
    setErrors,
    setServerError,
    setOpen,
    handleMemberChange,
    handleTeamChange,
    onsubmitHandler,
    onHandlerBlur,
  };
};
export default useRegistrationform;

import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@mui/material";
import iconBox from "../../assets/Iconbrightness.png";
import CustomeImage from "../comman/CustomeImage";

const UseCaseCard = ({ icon, label, highlighted, handlClickOnCard }) => {
  return (
    <Card
      sx={{
        width: { xs: "100%", sm: "100%" },
        height: { xs: "100%", sm: "90%" },

        backgroundColor: highlighted ? "orange" : "white",
        color: highlighted ? "white" : "black",
        borderRadius: "24px",
        margin: "auto",
        boxShadow: highlighted ? 3 : 1,
      }}
    >
      <CardActionArea onClick={handlClickOnCard}>
        <CardContent
          sx={{
            textAlign: "center",
            // padding: { xs: "0.6rem", md: "1.4rem" },
          }}
        >
          <Box
            sx={{
              width: { xs: "45px", md: "55px" },
              height: { xs: "45px", md: "55px" },
              borderRadius: "50%",
              margin: "auto",
              backgroundImage: `URL(${iconBox})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflowWrap: "break-word",
            }}
          >
            <CustomeImage
              src={icon}
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
              }}
            />
          </Box>
          <Typography
            variant="caption"
            sx={{
              color: highlighted ? "white" : "#030303",
              fontSize: { xs: "14px", md: "16px" },
              fontWeight: "400",
              letterSpacing: "2px",
              lineHeight: "18px",
              marginTop: { xs: "8px", md: "16px" },
              textTransform: "uppercase",
            }}
          >
            {label}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default UseCaseCard;

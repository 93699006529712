import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";

const AnswerComponent = ({ answerData }) => {
  return (
    <Box sx={{ textAlign: "center" }}>
      {Array.isArray(answerData) ? (
        answerData.map((answer, index) => (
          <Box key={index} sx={{ marginBottom: 2 }}>
            {" "}
            {answer.AnsTitle && (
              <Typography
                align="left"
                variant="subtitle1"
                padding={3}
                sx={{ fontWeight: "bold" }}
              >
                {answer.AnsTitle}
              </Typography>
            )}
            {answer.ans && (
              <List
                sx={{
                  listStyleType: "disc",
                  pl: 2,
                  marginLeft: { xs: "20px", md: "50px" },
                  paddingTop: 0,
                }}
              >
                <ListItem
                  sx={{
                    display: "list-item",
                    padding: 0,
                  }}
                >
                  <ListItemText primaryTypographyProps={{ variant: "body1" }}>
                    {answer.ans}
                  </ListItemText>
                </ListItem>
              </List>
            )}
            {answer.AnsBottonText && (
              <Typography
                align="left"
                padding={3}
                variant="subtitle1"
                sx={{ fontWeight: "bold" }}
              >
                {answer.AnsBottonText}
              </Typography>
            )}
          </Box>
        ))
      ) : (
        <Box sx={{ padding: 2 }}>
          <Typography align="justify">{answerData.ans}</Typography>
        </Box>
      )}
    </Box>
  );
};
export default AnswerComponent;

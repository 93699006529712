export const prizes = [
  {
    place: "1st Place",
    amount: "Rs 30,000",
    color: "#FFD700",
  },
  {
    place: "2nd Place",
    amount: "Rs 15,000",
    color: "#C0C0C0",
  },
  {
    place: "3rd Place",
    amount: "Rs 5,000",
    color: "#CD7F32",
  },
];

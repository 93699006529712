import { Box, Typography } from "@mui/material";
import { stringStr } from "../constant/String";

const Footer = () => {
  return (
    <Box sx={{ marginTop: 10, marginLeft: 10, marginBottom: 5 }}>
      <Typography variant="h5">{stringStr.logoName}</Typography>
      <Typography variant="subtitle2">{stringStr.footerDate}</Typography>
    </Box>
  );
};
export default Footer;

import { stringStr } from "./String";

export const UseCases = [
  stringStr.UseCasesMenu.usesCasesCardStrEnhancedUnderWritingIsuuance,
  stringStr.UseCasesMenu.usesCasesCardStrFinancialAdPortal,
  stringStr.UseCasesMenu.usesCasesCardStrGenzEngagement,
  stringStr.UseCasesMenu.usesCasesCardStrHNIUp_SellAndCross,
  stringStr.UseCasesMenu.usesCasesCardStrRetirmentBusinessGrowth,
  stringStr.UseCasesMenu.usesCasesCardStrWhatsappJourneyEnablement,
];

import { Box, Grid, Typography } from "@mui/material";

export const TeamSection = ({ title, members }) => (
  <Box sx={{ alignItems: "center" }}>
    <Typography
      sx={{
        color: "#030303",
        fontSize: "32px",
        fontFamily: "Poppins",
        fontWeight: 600,
        lineHeight: "42px",
        textAlign: "center",
      }}
    >
      {title}
    </Typography>
    <Grid item xs={12} sm={6} md={3} sx={{ display: "flex", padding: 1 }}>
      {members.map((member, index) => (
        <Typography
          key={index}
          variant="h6"
          sx={{
            width: "45px",
            marginTop: 2,
            padding: 4,
            color: "#030303",
            fontSize: "15px",
            fontFamily: "Poppins",
            fontWeight: 600,
            lineHeight: "26px",
            textAlign: "center",
          }}
        >
          {member}
        </Typography>
      ))}
    </Grid>
  </Box>
);

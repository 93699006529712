import React from "react";
import { Container, Grid, Typography } from "@mui/material";

import { judges } from "../../constant/judges";
import JudgeCard from "./JudgesCard";

const JudgesAndWinningCriteria = () => {
  return (
    <Container sx={{ marginTop: 7 }}>
      <Typography
        variant="h5"
        align="center"
        sx={{ marginBottom: 2, fontSize: "40px", fontWeight: 600 }}
      >
        Judges & Winning Criteria
      </Typography>
      <Typography variant="subtitle1" align="center" sx={{ marginBottom: 4 }}>
        Know what the judges are expecting with the next big idea
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        {judges.map((judge, index) => (
          <JudgeCard key={index} {...judge} />
        ))}
      </Grid>
    </Container>
  );
};

export default JudgesAndWinningCriteria;

import { Box, Button, MenuItem } from "@mui/material";
import { stringStr } from "../../constant/String";
import { Link } from "react-router-dom";

const LargeScreenHeader = (props) => (
  <Box
    sx={{
      flexGrow: 1,
      display: {
        xs: "none",
        md: "flex",
      },
      color: "#fffff",
    }}
  >
    {props.MenuItems.map((item) => (
      <MenuItem key={item.value}>
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Link
            to={item.path}
            style={{
              textDecoration: "none",
              color: props.activeMenu === item.value ? "#f26c05" : "inherit",
              borderRadius: "4px",
              fontWeight: 600,
            }}
            onClick={() => {
              props.handlerMenuClick(item.value);
            }}
          >
            {item.label}
          </Link>
        </Box>
      </MenuItem>
    ))}

    <Box
      sx={{
        flexGrow: 1,
        display: {
          xs: "none",
          md: "flex",
        },
      }}
    >
      <Button
        onClick={props.onSubmitHandler}
        sx={{
          my: 2,
          color: "white",
          display: "block",
          backgroundColor: "#f26c05",
          textTransform: "capitalize",
          fontWeight: 600,
        }}
        variant="contained"
      >
        {stringStr.registerNow}
      </Button>
    </Box>
  </Box>
);
export default LargeScreenHeader;

export const phases1 = [
  {
    title: "Team Registration Window Closed",
    description:
      "Registration window opens for DigiTech & AI Works teams to form their dream squads of 3-5 members.",
    date: "1st September",
  },
  {
    title: "Orientation & Training",
    description:
      "Orientation & Training by AWS & Innovation Teams to familiarize participants with the entire Hackathon process & best practices.",
    date: "5th September",
  },
  {
    title: "Simplifying Tech Infrastructure",
    description:
      "Workshop on the entire technology landscape and infrastructure revolving around the Hackathon event.",
    date: "6th September",
  },
];
export const phases2 = [
  {
    title: "Concept Submissions",
    description:
      "Submission of Ideas and concepts in a standardized format within stipulated timelines for further evaluations.",
    date: "10th September",
  },
  {
    title: "Building & Support Team vetting",
    description:
      "All submitted solutions to be evaluated by Business & Support Function SPOCs for any course-correction measures or tips on improvement.",
    date: "10th - 20th September",
  },
  {
    title: "Hackathon Day 1 & 2",
    description:
      "Rapid prototyping to create MVPs for demos and evaluations over 2 days to announce the final winners.",
    date: "6th September",
  },
];

import { Box, Typography } from "@mui/material";

export const BoxesForDetails = ({ content1, content2, content3, border }) => (
  <Box
    sx={{
      height: "auto",
      display: "flex",
      borderTop: border ? 1 : "",
      textAlign: "center",
      justifyContent: "space-between",
      overflowWrap: "normal",
    }}
  >
    <Typography
      sx={{
        padding: 2,
        width: "25%",
        color: "#142316",
        fontSize: "1rem",
        fontFamily: "M PLUS 1",
        fontWeight: 500,

        textAlign: "center",
        lineHeight: "20px",
      }}
    >
      {content1}
    </Typography>
    <Typography
      sx={{
        padding: 2,
        width: "25%",
        color: "#142316",
        fontSize: "1rem",
        fontFamily: "M PLUS 1",
        fontWeight: 500,

        textAlign: "center",
        lineHeight: "20px",
      }}
    >
      {content2}
    </Typography>
    <Typography
      sx={{
        padding: 2,
        width: "25%",
        color: "#142316",
        fontSize: "1rem",
        fontFamily: "M PLUS 1",
        fontWeight: 500,

        textAlign: "center",
        lineHeight: "20px",
      }}
    >
      {content3}
    </Typography>
  </Box>
);

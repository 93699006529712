import { Box, Container, Typography } from "@mui/material";
import imgae1 from "../../assets/listeningimg.png";
import { useNavigate } from "react-router-dom";
import CustomeImage from "./CustomeImage";
import RegisterButton from "./RegisterButton";

const BottamCard = ({ title1, title2, subTitle, buttonLabel }) => {
  const navigate = useNavigate();
  const onClickHandler = () => {
    navigate("/registration");
  };
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#fffff",
        borderRadius: "10px",
        boxShadow: 1,
        padding: "1rem",
        marginTop: "2rem",
      }}
    >
      <Box sx={{}}>
        <Box sx={{ marginTop: "35px" }}>
          <Typography
            variant="h4"
            sx={{ fontSize: "40px", fontWeight: 600, paddingLeft: 4 }}
          >
            {title1}
          </Typography>
          <Typography
            variant="h4"
            sx={{ fontSize: "40px", fontWeight: 600, paddingLeft: 4 }}
          >
            {title2}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ fontSize: "15px", paddingLeft: 4 }}
          >
            {subTitle}
          </Typography>
          <Box sx={{ paddingLeft: 4, marginTop: "3rem" }}>
            <RegisterButton onClickHandler={onClickHandler} type="submit">
              {buttonLabel}
            </RegisterButton>
          </Box>
        </Box>
      </Box>
      <Box>
        <CustomeImage
          style={{
            backgroundPosition: "center center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          src={imgae1}
          alt={"Bottam Card"}
        />
      </Box>
    </Container>
  );
};
export default BottamCard;

import { InputBase } from "@mui/material";

const FormTextFeilds = ({
  label,
  required,
  value,
  onChange,
  type,
  placeholder,
  onBlur,
  error,
  helperText,
}) => {
  return (
    <InputBase
      sx={{
        top: "20px",
        borderRadius: "24px",
        backgroundColor: "#ffffff",
        color: "#517357",
        fontSize: "20px",
        padding: 2,
        fontFamily: "Questrial",
      }}
      fullWidth
      label={label}
      type={type}
      placeholder={placeholder}
      required={required}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      helpertext={helperText ? helperText : ""}
    />
  );
};
export default FormTextFeilds;

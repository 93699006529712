import { KeyboardArrowUp } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import UseHandleGoToTop from "../../Hooks/UseHandleGoToTop";

export const ScrollUpArrow = () => (
  <Box
    sx={{
      display: "flex",
      flex: 1,
      justifyContent: "end",
      mr: "20px",
    }}
  >
    <IconButton
      sx={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        mt: "1rem",
      }}
      onClick={() => {
        UseHandleGoToTop();
      }}
    >
      <Typography
        sx={{
          fontSize: "10px",
        }}
      >
        Scroll To Top
      </Typography>
      <KeyboardArrowUp
        sx={{
          backgroundColor: "#f26c05",
          color: "#fff",
          borderRadius: "50%",
          fontSize: "30px",
        }}
      ></KeyboardArrowUp>
    </IconButton>
  </Box>
);

import img1 from "../assets/prasanttripathy.png";
import img2 from "../assets/sachinsaxena.png";
import img3 from "../assets/manulavanya.png";
import img4 from "../assets/suhailghai.png";
export const judges = [
  {
    name: "Prashant Tripathy",
    title: "CEO",
    img: img1,
    criteria:
      "Ideas should be creative and innovative ways of solving problems",
  },
  {
    name: "Sachin Saxena",
    title: "CRO",
    img: img2,
    criteria: "Ideas need to be practical and feasible to implement",
  },
  {
    name: "Manu Lavanya",
    title: "COO",
    img: img3,
    criteria:
      "Ideas should solve the business problem and drive the intended objective",
  },
  {
    name: "Suhail Ghai",
    title: "CTO",
    img: img4,
    criteria: "Ideas should be working prototypes that can be tested",
  },
];

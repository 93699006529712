import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
} from "@mui/material";

import { ArrowForward } from "@mui/icons-material";

const PhaseCard = ({ title, description, date }) => (
  <Card
    sx={{
      width: "270px",
      height: "300px",
      display: "flex",
      borderRadius: "15x",
      flexDirection: "column",
      justifyContent: "space-evenly",
      padding: 3,
    }}
  >
    <CardContent>
      <Typography
        variant="h5"
        component="div"
        sx={{ fontWeight: 600, fontSize: "25px", marginBottom: 2 }}
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
        width="200px"
        color="text.secondary"
        sx={{ marginBottom: 2 }}
      >
        {description}
      </Typography>
    </CardContent>
    <Box sx={{ alignSelf: "flex-start", marginTop: "5px" }}>
      <Button
        variant="contained"
        sx={{
          alignSelf: "flex-start",
          backgroundColor: "#f26c05",
          fontWeight: "bold",
          borderRadius: "10px",
          border: "1px solid #030303",
        }}
      >
        {date}
      </Button>
    </Box>
  </Card>
);

const Phases = ({ data, title }) => {
  return (
    <Container sx={{ marginTop: 4 }}>
      <Typography
        variant="h6"
        sx={{ marginBottom: 4, fontWeight: 500, fontSize: "24px" }}
      >
        {title}
      </Typography>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        {data.map((phase, index) => (
          <React.Fragment key={index}>
            <Grid item>
              <PhaseCard {...phase} />
            </Grid>
            {index < data.length - 1 && (
              <Grid item>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <ArrowForward sx={{ fontSize: 35 }} />
                </Box>
              </Grid>
            )}
          </React.Fragment>
        ))}
      </Grid>
    </Container>
  );
};

export default Phases;

import { Box, Typography } from "@mui/material";
import FormTextFeilds from "./FormTextFeilds";
import { stringStr } from "../../constant/String";

const MemberBox = ({
  member,
  index,
  handleMemberChange,
  isRequired,
  onBlur,
  errors,
  serverError,
}) => {
  return (
    <Box sx={{ marginBottom: 2 }}>
      <Typography variant="subtitle1">{`Member -${index + 1} ${
        isRequired ? "(Required)" : ""
      }`}</Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <Box sx={{ flex: "1 1 100%" }}>
          <FormTextFeilds
            label={stringStr.formLabel.nameandsurname}
            required={isRequired}
            value={member.MemberName}
            type="text"
            placeholder={stringStr.formLabel.nameandsurname}
            onChange={(e) => {
              handleMemberChange(index, "MemberName", e.target.value);
            }}
            onBlur={() => {
              onBlur(`Members[${index}].MemberName`);
            }}
            error={!!errors?.MemberName ? true : false}
            helperText={errors?.MemberName || ""}
          />

          <Box sx={{ color: "red", marginTop: "20px" }}>
            {errors.MemberName}
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: "16px" }}>
          <Box sx={{ flex: 1 }}>
            <FormTextFeilds
              label={stringStr.formLabel.email}
              required
              value={member.MemberEmail}
              type="email"
              placeholder={stringStr.formLabel.email}
              onChange={(e) => {
                handleMemberChange(index, "MemberEmail", e.target.value);
              }}
              onBlur={() => onBlur(`Members[${index}].MemberEmail`)}
              error={errors?.MemberEmail ? true : false}
              helperText={errors?.MemberEmail || ""}
            />
            <Box sx={{ color: "red", marginTop: "20px" }}>
              {errors.MemberEmail}
            </Box>
            {serverError && (
              <Box sx={{ color: "red", marginTop: "20px" }}>
                {serverError.MemberEmail}
              </Box>
            )}
          </Box>
          <Box sx={{ flex: 1 }}>
            <FormTextFeilds
              label={stringStr.formLabel.role}
              required
              placeholder={stringStr.formLabel.role}
              value={member.MemberRole}
              type="text"
              onChange={(e) => {
                handleMemberChange(index, "MemberRole", e.target.value);
              }}
              onBlur={() => onBlur(`Members[${index}].MemberRole`)}
              error={errors?.MemberRole ? true : false}
              helperText={errors?.MemberRole || ""}
            />
            <Box sx={{ color: "red", marginTop: "20px" }}>
              {errors.MemberRole}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default MemberBox;

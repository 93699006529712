import { Box, Button, Container, Typography } from "@mui/material";
import bannerImage from "../../assets/Image.png";
import { useNavigate } from "react-router-dom";
import { stringStr } from "../../constant/String";
import CustomeImage from "./CustomeImage";
import RegisterButton from "./RegisterButton";

const styles = {
  ImageContainer: {
    width: "100%",
    height: "auto",
    maxWidth: "20rem",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
};

const HomeBanner = () => {
  const navigate = useNavigate();

  const onClickHandler = () => {
    navigate("/registration");
  };

  const onClickHandlerUseCases = () => {
    navigate("/usecases");
  };

  return (
    <>
      <Box
        sx={{
          margin: { xs: "2rem 0", md: "9rem 0 0 0" },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          gap: 3,
          textAlign: { xs: "center", md: "left" },
        }}
      >
        <Box
          sx={{
            color: "#030303",
            fontSize: { xs: "40px", md: "72px" },
            fontFamily: "poppins",
            fontWeight: 600,
            lineHeight: { xs: "50px", md: "80px" },
          }}
        >
          <Typography variant="h2">{stringStr.homeBuildThe} </Typography>
          <Typography variant="h2">{stringStr.homeNextBigIdea}</Typography>
          <Typography
            variant="h6"
            sx={{
              fontSize: { xs: "16px", md: "24px" },
              marginTop: { xs: "1rem", md: "2rem" },
            }}
          >
            {stringStr.joinText}
          </Typography>
        </Box>
        <CustomeImage
          src={bannerImage}
          alt="Banner Image"
          style={styles.ImageContainer}
        />
      </Box>

      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          justifyContent: { xs: "center", md: "center" },
          alignItems: "center",
          gap: 2,
          padding: { xs: "10px 0", md: "15px" },
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <RegisterButton onClickHandler={onClickHandler} />

        <Button
          variant="outlined"
          onClick={onClickHandlerUseCases}
          sx={{
            border: "1px solid #030303",
            boxSizing: "border-box",
            borderRadius: "12px",
            backgroundColor: "rgba(49,100,244,0)",
            color: "#030303",
            fontSize: "16px",
            textTransform: "capitalize",
            fontWeight: 600,
            width: { md: "50%" },
          }}
        >
          {stringStr.exploreUseCases}
        </Button>
      </Container>
    </>
  );
};

export default HomeBanner;

export const MentorshipTeam = {
  TitleOfSection: " Meet the Mentorship Team",

  TitleOfSectionSubTitle:
    " Know the people who will guide you, every step of the way",

  InnovationTeam: {
    Title: "Innovation Team",
    name1: "Colin Fernandes",
    name2: "Rishi Shrivastava",
    name3: "Deep Padh",
    name4: "Vibhu Mishra",
  },
  AWSTeam: {
    Title: "AWS Team",
    name1: "Namita",
    name2: "Sagar",
    name3: "Saurav",
  },
  BusinessSPOCs: {
    Title: "Business SPOCs",
    name1: "Biji",
    name2: "Vineet",
    name3: "Sameer",
    name4: "Sumit",
  },
  SupportSPOCs: {
    Title: "Support SPOCs",
    name1: "Puneet (Legal)",
    name2: "Ramnish (EA)",
    name3: "Yogesh (InfoSec)",
  },
};

import { Typography } from "@mui/material";

export const HeaderEventTypography = ({ title }) => (
  <Typography
    sx={{
      color: "#030303",
      fontSize: "20px",
      fontFamily: "M PLUS 1",
      fontWeight: 600,
      lineHeight: "31px",
      padding: 2,
    }}
  >
    {title}
  </Typography>
);

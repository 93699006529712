export const stringStr = {
  logoName: "DigiHack",
  menuHome: "Home",
  menuUseCases: "Use Cases",
  menuEventDeatils: "Event Details",
  menuFAQ: "FAQ",
  registerNow: "Register Now",
  exploreUseCases: "Explore Use Cases",
  joinText:
    "Join the DigiHack hackathon and put your skills to the test for chance to win exciting prizes.",
  homeBuildThe: "Build the",
  homeNextBigIdea: "Next Big Idea",
  location: "Location",
  date: "Date",
  eligibility: "Eligibility",
  prizes: "Prizes",
  prizesValue: "Rewards worth Rs 1Lac+",
  eligibilityValue: "DigiTech & AI Works",
  dateValue: "01/07/2024 - 23/07/2024",
  locationValue: "India",
  useCasesSubHeading:
    "Know the problems that require solving with your Big Idea",

  UseCasesMenu: {
    usesCasesCardStrGenzEngagement: "GEnZ Engagement",
    usesCasesCardStrHNIUp_SellAndCross: "HNI Up-sell and Cross-sell",
    usesCasesCardStrFinancialAdPortal: "Financial Advisory Portal",
    usesCasesCardStrWhatsappJourneyEnablement: "Whatsapp Journey Enablement",
    usesCasesCardStrEnhancedUnderWritingIsuuance:
      "Enhanced underwriting & isuuance",
    usesCasesCardStrRetirmentBusinessGrowth: "Retirment Business Growth",
  },

  UseCasesCardContent: {
    EnhancedUnderwritingAndIssurance: {
      Title: "Enhanced Underwriting & Issuance",
      TitleOverview:
        " How might we create non-inclusive Underwriting ecosystem to reduce our Policy issuance TAT & reduce Underwriting issues using websites like Binah.ai",

      BusinessContext: {
        Title: "Business Context",
        TitleSubTitle:
          "The insurance sector struggles to attract GenZ due to perceptions of it being outdated, complicated, uninteresting and irrelevant",
      },
      KeyChallenge: {
        Title: "Key Challenge",
        TitleSubTitle:
          "How might we create non-inclusive Underwriting ecosystem to reduce our Policy issuance TAT & reduce Underwriting issues using websites like Binah.ai?",
      },
      KeyObjectives: {
        Title: "Key Objectives",
        TitleSubTitle:
          "How might we create non-inclusive Underwriting ecosystem to reduce our Policy issuance TAT & reduce Underwriting issues using websites like Binah.ai?",
      },
    },
    GenzEngagement: {
      Title: "Enhanced Underwriting & Issuance",
      TitleOverview:
        "How might we make insurance appealing and relevant to GenZ?",

      BusinessContext: {
        Title: "Business Context",
        TitleSubTitle:
          "The insurance sector struggles to attract GenZ due to perceptions of it being outdated, complicated, uninteresting and irrelevant",
      },
      KeyChallenge: {
        Title: "Key Challenge",
        TitleSubTitle:
          "GenZ finds insurance products unappealing and complicated leading to low engagement, minimal uptake and unawareness with Insurance products",
      },
      KeyObjectives: {
        Title: "Key Objectives",
        TitleSubTitle:
          "Creating appealing, easy-to-understand & dedicated platform for GenZ audiences",
      },
    },
    HNIUpSellAndCrossSell: {
      Title: "Enhanced Underwriting & Issuance",
      TitleOverview:
        "How might we boost cross-selling and engagement for HNI and NRI customers?",

      BusinessContext: {
        Title: "Business Context",
        TitleSubTitle:
          "HNI and NRI segments are lucrative but underleveraged due to a lack of targeted engagement and cross-selling. We have currently mandated branch walk-ins for surrender processing",
      },
      KeyChallenge: {
        Title: "Key Challenge",
        TitleSubTitle:
          "Existing cross-selling strategies are generic and fail to engage HNI/NRI customers effectively",
      },
      KeyObjectives: {
        Title: "Key Objectives",
        TitleSubTitle:
          "Creating a dedicated platform for cross-selling strategies and engagement tactics specifically for HNI and NRI segments.",
      },
    },
    WhatsappJourneyEnablement: {
      Title: "Enhanced Underwriting & Issuance",
      TitleOverview:
        "How might we use WhatsApp to enhance customer engagement as well as providing convenient and valuable services?",

      BusinessContext: {
        Title: "Business Context",
        TitleSubTitle:
          "WhatsApp is a widely used communication platform that is increasingly being leveraged for enhancing customer engagement and providing simplified customer journey options",
      },
      KeyChallenge: {
        Title: "Key Challenge",
        TitleSubTitle:
          "Our current processes are deeply integrated into internal assets resulting in underutilization of WhatsApp for customer engagement and service delivery and creating a gap that needs to be filled for simplified customer service and operations.",
      },
      KeyObjectives: {
        Title: "Key Objectives",
        TitleSubTitle:
          "Leveraging WhatsApp as a platform and building essential capabilities for simplified customer operations throughout the entire policy life cycle in creative and engaging ways for ease of customer convenience and quick resolutions.",
      },
    },
    RetirmentBusinessGrowth: {
      Title: "Enhanced Underwriting & Issuance",
      TitleOverview:
        "How might we make retirement planning products more attractive and accessible?",

      BusinessContext: {
        Title: "Business Context",
        TitleSubTitle:
          "Retirement products are an essential aspect of sound financial planning which are often overlooked, delayed or not significantly considered.",
      },
      KeyChallenge: {
        Title: "Key Challenge",
        TitleSubTitle:
          "Low uptake of retirement planning products due to complexity of specifics, lack of awareness, unattractive propositions, competing products and targeting right customers.",
      },
      KeyObjectives: {
        Title: "Key Objectives",
        TitleSubTitle:
          "Simplify the product need, enhance the attractiveness of retirement products, enable strategic partnerships for distribution and creating customer targeting strategies to boost customer interest and uptake.",
      },
    },
    FinancialAdvisoryPortal: {
      Title: "Enhanced Underwriting & Issuance",
      TitleOverview:
        " How might we create non-inclusive Underwriting ecosystem to reduce our Policy issuance TAT & reduce Underwriting issues using websites like Binah.ai",

      BusinessContext: {
        Title: "Business Context",
        TitleSubTitle:
          "The insurance sector struggles to attract GenZ due to perceptions of it being outdated, complicated, uninteresting and irrelevant",
      },
      KeyChallenge: {
        Title: "Key Challenge",
        TitleSubTitle:
          "How might we create non-inclusive Underwriting ecosystem to reduce our Policy issuance TAT & reduce Underwriting issues using websites like Binah.ai?",
      },
      KeyObjectives: {
        Title: "Key Objectives",
        TitleSubTitle:
          "How might we create non-inclusive Underwriting ecosystem to reduce our Policy issuance TAT & reduce Underwriting issues using websites like Binah.ai?",
      },
    },
  },

  LearnMoreButton: "Learn More",
  registrationTitle: "Registration Form",
  registrationSubTitle:
    "Gather your dream team for a chance to be the next Big Idea",
  footerDate: "2024 © Max Life Insurance",
  thankYou: " Thanks for Registring!",
  thankYouSubTitle:
    "Thanks for registring for the Hackathon. Our team will be in touch with you for next steps and to help you craft a winning solution on your Big Idea",

  formLabel: {
    formTeamTitle: "Team Details*",
    formMemberTitle: "Member Details*",
    teamName: "Team Name",
    useCase: "Select the Use Case you are solving",
    nameandsurname: "Name and Surname",
    email: "Email Address",
    role: "Role",
    submitButton: "Submit Registration",
    snackbarMessage: "successful Registration",
  },

  error: {
    name: "Name is Required!",
    email: "Email is Required!",
    invalidEmail: "Inavlid email format",
    role: "Role is Required!",
    teamName: "Team name is Required!",
    useCase: "Use Cases is Required!",
  },

  eventDetailTitle: "Event Phases",
  eventDetailSubtitle: " An overview of all event phases",
  phase1Str: "Phase 1: Introduction & Set-up (1st to 6th September)",
  phase2Str: "Phase 2: Submission & Evaluation (10th to 22nd September)",

  ListeningCardHome: {
    ListeningCardTitle1: "Got a Big? Idea",
    ListeningCardTitle2: "We are Listening",
    ListeningCardSubTitle: "Take the first step towards making your idea real",
  },
  ListeningCardUseCase: {
    ListeningCardTitle1: "Challenge",
    ListeningCardTitle2: "Accepted?",
    ListeningCardSubTitle: "Take the first step towards making your idea real",
  },
  ListeningCardEventDetails: {
    ListeningCardTitle1: "Sounds",
    ListeningCardTitle2: "Interesting?",
    ListeningCardSubTitle: "Take the first step towards making your idea real",
  },
  AboutEventTitle: "About The Event",
  ABoutEventSubTitle:
    "DigiHack is a rapid prototyping hackathon intended to find creative solutions to pressing business problems. Over the course of the event, you will get a chance to work in teams, brainstorm innovative solutions and develop working prototypes that you think could transform the future of our industry.",

  whyParticipate: {
    title: "Why Participate?",
    text1: "Learn new skills and innovate",
    text2: "Collaborate & Network with colleagues",
    text3: "Get recognized by leaders & win exciting prizes",
  },
  whoParticipate: {
    title: "Who can Participate?",
    text1: "Only 3-5 Members Per Team from DigiTech & AI Works",
    text2:
      "Ideal Team Composition: Product/Project Manager Solution Architect Front-End Developer Back-End Developer Data/Dev-Ops Expert",
  },
  whatAreTheRule: {
    title: "What are the Rules?",
    text1: "Adhere to deadlines & Format",
    text2: "Choose 1 Team and 1 Use Case Only",
    text3: "Have Fun!",
  },
  HowDoIWin: {
    title: "How do I Win?",
    text1: "Build a working prototype",
    text2: "Solve the business problem",
    text3: "Think through everything - practicality, feasibility & viability",
  },

  EventDetailsMethodlogy: {
    conetntLine1: {
      content1: "Registration of Teams",
      content2: "Registration window open for participants",
      content3: "5th Aug to 16th Aug",
    },
    conetntLine2: {
      content1: "Orientation by Innovation Team",
      content2:
        "Participants to be informed on event expectations by the Innovation Team",
      content3: "7th Sep",
    },
    conetntLine3: {
      content1: "AWS Workshop",
      content2:
        "Generate Training on AWS ecosystem, technology specific understanding and working backwards from solution",
      content3: "7th Sep",
    },
    conetntLine4: {
      content1: "PR FAQ Submission",
      content2:
        "Concept submission of solution to be vetted by business teams, support functions and innovation team for any course-correction",
      content3: "8th Sep",
    },
    conetntLine5: {
      content1: "AWS Accounts Sharing",
      content2:
        "Technical ecosystem feasibility assessment to check if accounts assigned and new accounts required",
      content3: "10/11th Sep",
    },
    conetntLine6: {
      content1: "Hackathon Day 1",
      content2:
        "Making teams comfortable with AWS Setup, AWS Spoc assignment, MVP focus and any other assistance required for the team",
      content3: "1st Sep",
    },
    conetntLine7: {
      content1: "Hackathon Day 2",
      content2:
        "Demo and evaluations all working prototypes for feasibility and practicality by business heads, support SPOCs and judges",
      content3: "15th Sep",
    },
    conetntLine8: {
      content1: "Winner Announcements",
      content2:
        "Final awards ceremony and distribution of prizes to selected winners based on the winning criteria",
      content3: "23rd July",
    },
  },
};

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Toolbar,
  Typography,
  Menu,
  Container,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { stringStr } from "../../constant/String";

import { useLocation, useNavigate } from "react-router-dom";
import LargeScreenHeader from "./LargeScreenHeader";
import SmallScreenHeader from "./SmallScreenHeader";

const Header = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [activeMenu, setActiveMenu] = useState("");

  const MenuItems = [
    { label: stringStr.menuHome, value: "home", path: "/" },
    { label: stringStr.menuUseCases, value: "useCase", path: "/usecases" },
    {
      label: stringStr.menuEventDeatils,
      value: "EventDetail",
      path: "/eventdetails",
    },
    { label: stringStr.menuFAQ, value: "FAQ", path: "/faq" },
  ];
  const navigate = useNavigate();
  const location = useLocation();
  const onSubmitHandler = (e) => {
    e.preventDefault();
    setActiveMenu("");
    navigate("/registration");
  };

  const onLogoClickHandler = () => {
    navigate("/");
  };
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handlerMenuClick = (value) => {
    setActiveMenu(value);
  };
  useEffect(() => {
    const currentPath = location.pathname;
    const activeItem = MenuItems.find((item) => item.path === currentPath);
    setActiveMenu(activeItem ? activeItem.value : "");
  }, [location.pathname, MenuItems]);
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // const position = isSmallScreen ? "fixed" : "static";
  return (
    <Box
      top={0}
      mb={4}
      sx={{
        width: "100%",
      }}
    >
      <Container sx={{}}>
        <Toolbar disableGutters>
          <Typography
            onClick={onLogoClickHandler}
            variant="h6"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 600,
              letterSpacing: ".4rem",
              fontSize: "1.7rem",
              margin: "0 0 0 10rem",
              textDecoration: "none",
              cursor: "pointer",
              padding: "0.3rem 0.3rem 0.3rem 0.3rem ",
            }}
          >
            {stringStr.logoName}
          </Typography>
          <LargeScreenHeader
            activeMenu={activeMenu}
            MenuItems={MenuItems}
            onSubmitHandler={onSubmitHandler}
            handlerMenuClick={handlerMenuClick}
          ></LargeScreenHeader>
          <SmallScreenHeader
            anchorElNav={anchorElNav}
            activeMenu={activeMenu}
            MenuItems={MenuItems}
            onSubmitHandler={onSubmitHandler}
            handleOpenNavMenu={handleOpenNavMenu}
            handleCloseNavMenu={handleCloseNavMenu}
            handlerMenuClick={handlerMenuClick}
          ></SmallScreenHeader>

          <Typography
            variant="h5"
            noWrap
            component="a"
            onClick={onLogoClickHandler}
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              textAlign: "center",
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            DigiHack
          </Typography>

          <Box sx={{ flexGrow: 0 }}>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            ></Menu>
          </Box>
        </Toolbar>
      </Container>
    </Box>
  );
};
export default Header;

import { Box, Card, CardContent, Typography } from "@mui/material";
import { stringStr } from "../../constant/String";
import thankYouImg from "../../assets/thankyouimage.png";

import CustomeImage from "../comman/CustomeImage";

const ThankYouCard = (props) => (
  <Box
    sx={{
      width: {
        xs: "100%",
        md: "auto",
      },
      marginTop: {
        xs: "0.65rem",
        md: 0,
      },
      alignContent: {
        xs: "center",
      },
    }}
  >
    <Card
      sx={{
        width: { xs: "100%", md: "auto" },
        maxWidth: "400px",
        backgroundColor: "#f26c05",
        borderRadius: "16px",
        padding: 1,
        margin: "0 auto",
      }}
    >
      <CustomeImage
        src={thankYouImg}
        style={{
          padding: 4,
        }}
        alt="Thankyoupageimage"
      />
      <CardContent>
        <Typography
          sx={{
            color: "#ffffff",
            fontSize: "20px",
            fontFamily: "M PLUS 1",
            fontWeight: 700,
            lineHeight: "42px",
          }}
        >
          {`${stringStr.formLabel.teamName}: ${props.values.TeamName}`}
        </Typography>
        <Typography
          sx={{
            color: "#ffffff",
            fontSize: "20px",
            fontFamily: "M PLUS 1",
            fontWeight: 700,
            lineHeight: "42px",
          }}
        >
          {`${stringStr.menuUseCases}: ${props.values.Usecase}`}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "#f2ede4",
            fontSize: "15px",
            fontFamily: "Questrial",
            lineHeight: "31px",
          }}
        >
          {` Registered on: ${props.CurrentDate}`}
        </Typography>
      </CardContent>
    </Card>
  </Box>
);
export default ThankYouCard;

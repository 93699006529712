import { Box, CardContent, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomeImage from "../comman/CustomeImage";

const EventCardContent = ({
  space,
  astronut,
  microscope,
  text1,
  text2,
  text3,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const getImageStyle = () => ({
    width: isSmallScreen ? "80px" : isMediumScreen ? "80px" : "80px",
    height: "80px",
    borderRadius: "100%",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    marginBottom: isSmallScreen ? theme.spacing(1) : 0,
  });

  const getTypographyStyle = () => ({
    width: "80%",
    color: "#f45b31",
    fontSize: isSmallScreen ? "14px" : isMediumScreen ? "16px" : "18px",
    fontFamily: "Poppins",
    fontWeight: 400,
    lineHeight: isSmallScreen ? "20px" : isMediumScreen ? "20px" : "25px",

    marginLeft: isSmallScreen ? 0 : theme.spacing(1),
    marginTop: isSmallScreen ? theme.spacing(0) : 0,
  });

  const getBoxStyle = () => ({
    width: "80%",
    height: "411",
    display: "flex",
    flexDirection: isSmallScreen ? "column" : "row",
    alignItems: "center",
    marginBottom: isSmallScreen ? theme.spacing(1) : theme.spacing(1),
    alignItems: isSmallScreen ? "center" : "left",
  });

  return (
    <CardContent
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={getBoxStyle()}>
        <CustomeImage src={space} alt="space" style={getImageStyle()} />
        <Typography sx={getTypographyStyle()}>{text1}</Typography>
      </Box>
      <Box sx={getBoxStyle()}>
        <CustomeImage
          src={microscope}
          alt="microscope"
          style={getImageStyle()}
        />
        <Typography sx={getTypographyStyle()}>{text2}</Typography>
      </Box>
      <Box sx={getBoxStyle()}>
        {astronut && (
          <CustomeImage
            src={astronut}
            alt="astronaut"
            style={getImageStyle()}
          />
        )}
        <Typography sx={getTypographyStyle()}>{text3}</Typography>
      </Box>
    </CardContent>
  );
};

export default EventCardContent;

import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { stringStr } from "../../constant/String";

import UseCaseDetails from "./UseCaseDetails";
import EnhancedUnderwritingIssuance from "./EnhancedUnderwritingIssuance";
import UseCaseCard from "./Card";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useCases } from "../../constant/useCaseCardItems";

const UseCases = () => {
  const card = true;
  const [OpeningCardTitle, setOpeningCardTitle] = useState(useCases[0].Title);

  const [OpeningCardTitleOverview, setOpeningCardTitleOverview] = useState(
    useCases[0].TitleOverview
  );
  const [colorHighlighted, setColorHighlighted] = useState(
    useCases[0].highlighted
  );

  const [selectedUseCaseDetails, setSelectedUseCaseDetails] = useState({
    BusinessContextTitle: useCases[0].BusinessContext.Title,
    BusinessContextOverview: useCases[0].BusinessContext.Overview,
    KeyChallengeTitle: useCases[0].KeyChallenge.Title,
    KeyChallengeOverview: useCases[0].KeyChallenge.Overview,
    KeyObjectiveTitle: useCases[0].KeyObjective.Title,
    KeyObjectiveOverview: useCases[0].KeyObjective.Overview,
  });

  const currentPath = useLocation();

  const onHandlClickCard = (title, titleOverview, index) => {
    useCases.forEach((item, i) => {
      if (index === i) {
        setOpeningCardTitle(title);
        setOpeningCardTitleOverview(titleOverview);
        setColorHighlighted(index);
        setSelectedUseCaseDetails({
          BusinessContextTitle: item.BusinessContext.Title,
          BusinessContextOverview: item.BusinessContext.Overview,
          KeyChallengeTitle: item.KeyChallenge.Title,
          KeyChallengeOverview: item.KeyChallenge.Overview,
          KeyObjectiveTitle: item.KeyObjective.Title,
          KeyObjectiveOverview: item.KeyObjective.Overview,
        });
      }
    });
  };

  return (
    <Box>
      <Box sx={{ textAlign: "center", padding: { xs: "15px", md: "20px" } }}>
        <Container sx={{ marginTop: "20px" }}>
          <Typography
            variant="h4"
            sx={{ fontSize: { xs: "2rem", md: "3rem" }, fontWeight: 600 }}
          >
            {stringStr.menuUseCases}
          </Typography>
          <Typography variant="subtitle1">
            {stringStr.useCasesSubHeading}
          </Typography>
        </Container>
      </Box>

      <Grid
        container
        spacing={2}
        sx={{
          borderRadius: "24px",
          borderColor: "#fffff",
          // padding: { xs: "15px", md: "20px" },
          justifyContent: "center",
        }}
      >
        {useCases.map((useCase, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <UseCaseCard
              icon={useCase.icon}
              label={useCase.Title}
              highlighted={colorHighlighted === index}
              handlClickOnCard={(e) => {
                onHandlClickCard(useCase.Title, useCase.TitleOverview, index);
              }}
            />
          </Grid>
        ))}
      </Grid>

      {card && (
        <>
          <Box
            sx={{
              margin: { xs: "10px 0", md: "0px 0" },
              padding: { xs: "20px", md: "20px" },
              display: "flex",
              justifyContent: "center",
            }}
          >
            <EnhancedUnderwritingIssuance
              title={OpeningCardTitle}
              overview={OpeningCardTitleOverview}
              ButtonNameHome={
                currentPath.pathname === "/usecases"
                  ? stringStr.registerNow
                  : stringStr.LearnMoreButton
              }
            />
          </Box>

          {currentPath.pathname === "/usecases" ? (
            <Box
              sx={{
                margin: { xs: "15px 0", md: "25px 0" },
                padding: { xs: "20px", md: "20px" },
                display: "flex",
                justifyContent: "center",
              }}
            >
              <UseCaseDetails
                BusinessContextTitle={
                  selectedUseCaseDetails.BusinessContextTitle
                }
                BusinessContextOverview={
                  selectedUseCaseDetails.BusinessContextOverview
                }
                KeyChallengeTitle={selectedUseCaseDetails.KeyChallengeTitle}
                KeyChallengeOverview={
                  selectedUseCaseDetails.KeyChallengeOverview
                }
                KeyObjectiveTitle={selectedUseCaseDetails.KeyObjectiveTitle}
                KeyObjectiveOverview={
                  selectedUseCaseDetails.KeyObjectiveOverview
                }
              />
            </Box>
          ) : (
            ""
          )}
        </>
      )}
    </Box>
  );
};
export default UseCases;

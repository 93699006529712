import { Box, Button, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const EnhancedUnderwritingIssuance = ({ title, overview, ButtonNameHome }) => {
  const navigate = useNavigate();
  const currentPath = useLocation();

  const onClickGoToUsecase = () => {
    if (currentPath.pathname === "/usecases") {
      navigate("/registration");
    } else {
      navigate("/usecases");
    }
  };

  return (
    <Box
      sx={{
        height: "auto",
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "space-between",
        alignItems: "center",
        padding: "24px",
        marginLeft: { xs: "1rem", md: "3.2rem" },
        marginRight: { xs: "1rem", md: "3.2rem" },
        border: "1px solid #E0E0E0",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          marginLeft: { xs: 0, md: "20px" },
          mb: { xs: 2, md: 0 },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            mb: 2,
            fontWeight: 600,
            fontSize: { xs: "1.5rem", md: "2rem" },
          }}
        >
          {title}
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#f26c05",
            color: "#FFFFFF",
            alignSelf: "start",
            borderRadius: "24px",
            textTransform: "capitalize",
            fontWeight: 600,
            paddingLeft: 3,
            paddingRight: 3,
            marginTop: "10px",
            "&:hover": {
              backgroundColor: "#FF8C00",
            },
          }}
          onClick={onClickGoToUsecase}
        >
          {ButtonNameHome}
        </Button>
      </Box>
      <Box
        sx={{
          flex: 1,
          paddingLeft: { xs: 0, md: "24px" },
          mt: { xs: 2, md: 0 },
        }}
      >
        <Typography
          variant="body1"
          sx={{
            mb: 2,
            fontSize: { xs: "0.875rem", md: "1rem" },
          }}
        >
          {overview}
        </Typography>
        <Typography
          variant="body2"
          fontWeight="bold"
          sx={{ fontSize: { xs: "0.875rem", md: "1rem" } }}
        >
          BUSINESS SPOC - BIJI SAMUEL
        </Typography>
      </Box>
    </Box>
  );
};

export default EnhancedUnderwritingIssuance;

import umbrella from '../assets/Iconumbrellabeach.png'
import kitesurfing from '../assets/Iconkitesurfing.png'
import terrain from '../assets/Iconterrain.png'
import compound from '../assets/Iconcampground.png'
import directioBoat from '../assets/Icondirectionsboat.png'
import rocketLaunch from '../assets/Iconrocketlaunch.png'
export  const image={
    umbrella,
    rocketLaunch,
    terrain,
    compound,
    directioBoat,
    kitesurfing
}
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { stringStr } from "../../constant/String";
import { useLocation, useNavigate } from "react-router-dom";
import ThankYouCard from "./ThankYouCard";

const ThankyouPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const Data = location.state;
  const values = Data === null ? "" : Data;

  const date = new Date();
  const CurrentDate = `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}`;

  const onClickHandler = () => {
    navigate("/");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        margin: { xs: "1rem", md: "6rem" },
        padding: 4,
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: { xs: "100%", md: "50%" },
          padding: { xs: 2, md: 4 },
          textAlign: { xs: "center", md: "left" },
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: "#030303",
            fontSize: { xs: "20px", md: "25px" },
            fontFamily: "M PLUS 1",
            fontWeight: 700,
            lineHeight: { xs: "35px", md: "47px" },
          }}
        >
          {stringStr.thankYou}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            paddingTop: "20px",
            color: "#030303",
            fontSize: { xs: "13px", md: "15px" },
            lineHeight: { xs: "25px", md: "31px" },
          }}
        >
          {stringStr.thankYouSubTitle}
        </Typography>
        <Box sx={{ marginTop: { xs: "20px", md: "50px" } }}>
          <Button
            sx={{
              cursor: "pointer",
              width: { xs: "100%", sm: "300px" },
              padding: 2,
              border: "0",
              boxSizing: "border-box",
              borderRadius: "32px",
              backgroundColor: "#f26c05",
              color: "#ffffff",
              fontSize: "20px",
              fontFamily: "M PLUS 1",
              fontWeight: "500",
              lineHeight: "26px",
              outline: "none",
            }}
            variant="contained"
            onClick={onClickHandler}
          >
            Go To Homepage
          </Button>
        </Box>
      </Box>
      <ThankYouCard values={values} CurrentDate={CurrentDate}></ThankYouCard>
    </Box>
  );
};

export default ThankyouPage;

import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { stringStr } from "../../constant/String";
import RegistrationCards from "./RegistrationCards";
import FormTextFeilds from "./FormTextFeilds";
import { Fragment, useEffect } from "react";
import useRegistrationform from "../../Hooks/CustomeHooks";
import MemberBox from "./MemberBox";
import { Validation } from "../Service/Validation";
import SnackbarMessage from "./SnackbarMessage";
import FormControlSelectComponent from "./FormControlSelectComponent";
import UsePageBottam from "../../Hooks/UsePageBottam";
import { ScrollUpArrow } from "../comman/ScrollUpArrow";

const initalFormState = {
  TeamName: "",
  Usecase: "",
  Members: [
    { MemberName: "", MemberRole: "", MemberEmail: "" },
    { MemberName: "", MemberRole: "", MemberEmail: "" },
    { MemberName: "", MemberRole: "", MemberEmail: "" },
    { MemberName: "", MemberRole: "", MemberEmail: "" },
    { MemberName: "", MemberRole: "", MemberEmail: "" },
  ],
};

const Registration = () => {
  const {
    formData,
    touched,
    errors,
    open,
    loader,
    serverError,
    handleMemberChange,
    onsubmitHandler,
    onHandlerBlur,
    setErrors,
    setOpen,
    handleTeamChange,
  } = useRegistrationform(initalFormState);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const showGoTop = UsePageBottam();

  useEffect(() => {
    if (
      touched.TeamName ||
      touched.Usecase ||
      touched.Members.flatMap(Object.values).includes(true)
    ) {
      const error = Validation(formData);
      error.Members = error.Members.map((member, index) =>
        index >= 3 && !member ? {} : member
      );
      setErrors(error);
    }
  }, [formData, setErrors, touched]);

  const handleClose = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleMenuSelected = (index, item) => {
    handleTeamChange(index, item);
  };
  const action = (
    <Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </Fragment>
  );

  return (
    <Container
      maxWidth="lg"
      sx={{ margin: "0 auto", padding: isSmallScreen ? "10px" : "20px" }}
    >
      <Box
        mt={6}
        sx={{
          textAlign: "center",
          marginBottom: 4,
        }}
      >
        <Typography variant="h4" sx={{ color: "#030303", fontWeight: 600 }}>
          {stringStr.registrationTitle}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: "#030303" }}>
          {stringStr.registrationSubTitle}
        </Typography>
      </Box>
      {/* Team Deatail feilds */}
      <Box component="form" noValidate onSubmit={onsubmitHandler}>
        <RegistrationCards title={stringStr.formLabel.formTeamTitle}>
          <FormTextFeilds
            label={stringStr.formLabel.teamName}
            required
            value={formData.TeamName}
            placeholder={stringStr.formLabel.teamName}
            type="text"
            onChange={(e) => {
              handleTeamChange("TeamName", e.target.value);
            }}
            onBlur={() => onHandlerBlur("TeamName")}
            error={touched.TeamName && !!errors.TeamName}
            helperText={touched.TeamName && errors.TeamName}
          />
          {errors.TeamName && (
            <Box sx={{ color: "red" }}>{errors.TeamName}</Box>
          )}
          {serverError && (
            <Box sx={{ color: "red" }}>{serverError.TeamName}</Box>
          )}

          <FormControlSelectComponent
            Usecase={formData.Usecase}
            touched={touched}
            errors={errors}
            onHandlerBlur={onHandlerBlur}
            handleTeamChange={handleTeamChange}
            // handleMenuSelected={handleMenuSelected}
          />

          {errors.Usecase && <Box sx={{ color: "red" }}>{errors.Usecase}</Box>}
        </RegistrationCards>
        {/* Members deatil feilds */}
        <RegistrationCards title={stringStr.formLabel.formMemberTitle}>
          {formData.Members.map((member, index) => (
            <Box key={index} sx={{ marginBottom: 2 }}>
              <MemberBox
                index={index}
                member={member}
                handleMemberChange={handleMemberChange}
                isRequired={index < 3}
                onBlur={(field) => onHandlerBlur(`Members[${index}].${field}`)}
                errors={
                  errors.Members &&
                  errors.Members[index] &&
                  Object.keys(errors.Members[index]).length
                    ? errors.Members[index]
                    : {}
                }
                serverError={serverError}
              />
            </Box>
          ))}

          <Box sx={{ textAlign: "center", marginTop: 4 }}>
            {loader ? (
              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "#f26c05",
                  textTransform: "capitalize",
                  fontWeight: 600,
                }}
                type="submit"
              >
                {stringStr.formLabel.submitButton}
              </Button>
            ) : (
              <CircularProgress sx={{ color: "#f26c05", fontSize: "10px" }} />
            )}
          </Box>
        </RegistrationCards>
      </Box>
      <SnackbarMessage open={open} handleClose={handleClose} action={action} />
      {showGoTop && <ScrollUpArrow />}
    </Container>
  );
};
export default Registration;

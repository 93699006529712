import { FormControl, MenuItem, Select } from "@mui/material";
import { stringStr } from "../../constant/String";
import { UseCases } from "../../constant/UseCasesMenuItem";

const FormControlSelectComponent = (props) => (
  <FormControl
    sx={{
      borderRadius: "24px",
      backgroundColor: "#ffffff",
      fontSize: "20px",
      padding: 1,
      fontFamily: "Questrial",
    }}
  >
    <Select
      labelId="usecase-label"
      value={props.Usecase}
      sx={{
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "white",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "white",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "white",
        },
      }}
      displayEmpty
      onChange={(e) => {
        props.handleTeamChange("Usecase", e.target.value);
      }}
      onBlur={() => props.onHandlerBlur("Usecase")}
      error={props.touched.Usecase && !!props.errors.Usecase}
      helperText={props.touched.Usecase && props.errors.Usecase}
    >
      <MenuItem value="">
        <em>{stringStr.formLabel.useCase}</em>
      </MenuItem>
      {UseCases.map((useCase, index) => (
        <MenuItem
          key={index}
          value={useCase}
          // onClick={() => {
          //   props.handleMenuSelected(index, useCase);
          // }}
        >
          {useCase}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
export default FormControlSelectComponent;

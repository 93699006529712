import {
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FAQContent, Questions } from "../../constant/FAQConstant";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { useState } from "react";
import LeftSideImage from "../../assets/listeningimg.png";
import QuestionComponent from "./QuestionComponent";
import AnswerComponent from "./AnswerComponent";
import CustomeImage from "../comman/CustomeImage";
import { ScrollUpArrow } from "../comman/ScrollUpArrow";
import usePageBottom from "../../Hooks/UsePageBottam";

const styles = {
  ImageContainer: {
    width: "100%",
    maxWidth: "20rem",
    height: "auto",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
};

const FAQ = () => {
  const [DisplayAnswer, setDisplayAnswer] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const reachedBottam = usePageBottom();

  const onQuestionClickHandler = (index) => {
    if (DisplayAnswer === index) {
      setDisplayAnswer(null);
    } else {
      setDisplayAnswer(index);
    }
  };

  return (
    <>
      <Box
        mt={6}
        sx={{
          alignItems: "center",
          textAlign: "center",
          padding: { xs: 2, md: 4 },
        }}
      >
        <Box
          sx={{
            borderRadius: "30px",
            boxShadow: 2,
            backgroundColor: "#f7f8f9",
            display: "inline-block",
            padding: { xs: "8px 15px", md: "11px 20px" },
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontSize: { xs: "16px", md: "20px" } }}
          >
            {FAQContent.Title}
          </Typography>
        </Box>

        <Box
          sx={{
            position: "relative",
            textAlign: "center",
            justifyContent: "center",
            marginTop: { xs: 2, md: 4 },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "24px", md: "32px" },
              color: "#f26c05",
              marginBottom: { xs: 1, md: 2 },
            }}
          >
            {FAQContent.HeadingTitle}
          </Typography>
          <HorizontalRuleIcon
            sx={{
              background: "linear-gradient(270deg, #f27830, #113874)",
              height: "0.3rem",
              borderRadius: "24px",
              width: { xs: "10%", md: "5%" },
              margin: "0 auto",
            }}
          />
        </Box>
      </Box>

      <Grid container spacing={4} sx={{ padding: 2 }}>
        {!isMobile && (
          <Grid item xs={12} sm={4}>
            <CustomeImage
              src={LeftSideImage}
              alt="side image"
              style={styles.ImageContainer}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={8}>
          {Questions.map((question, index) => (
            <Box
              key={index}
              sx={{
                textAlign: "center",
                margin: { xs: "0", md: "0 auto" },

                padding: 2,
                borderBottom: 1,
                borderColor: "rgba(0, 0, 0, 0.5)",
                outline: "2px solid #CCC",
                maxWidth: { xs: "100%", md: "80%" },
                marginLeft: { xs: 0, md: "50px" },
              }}
            >
              <QuestionComponent
                question={question.Question}
                onButtonClickHandler={() => {
                  onQuestionClickHandler(index);
                }}
              />
              {DisplayAnswer === index && (
                <AnswerComponent answerData={question.Answer} />
              )}
            </Box>
          ))}
        </Grid>
        {reachedBottam && <ScrollUpArrow />}
      </Grid>
    </>
  );
};

export default FAQ;

import { Box, Typography } from "@mui/material";
import { stringStr } from "../../constant/String";

const UseCaseDetails = ({
  BusinessContextTitle,
  BusinessContextOverview,
  KeyChallengeTitle,
  KeyChallengeOverview,
  KeyObjectiveTitle,
  KeyObjectiveOverview,
}) => {
  return (
    <Box>
      <Box sx={{ paddingTop: 6, paddingBottom: 2 }}>
        <Typography
          variant="h6"
          sx={{
            color: "#142316",
            fontSize: "25px",
            fontFamily: "Poppins",
            fontWeight: 600,
            lineHeight: "42px",
          }}
        >
          {BusinessContextTitle}
        </Typography>
        <Box
          sx={{
            borderBottom: 2,
            borderColor: "#f26c05",
            width: "7rem",
          }}
        ></Box>
        <Typography
          variant="subtitle1"
          sx={{
            color: "#030303",
            fontSize: "20px",
            fontFamily: "Poppins",
            lineHeight: "42px",
          }}
        >
          {BusinessContextOverview}
        </Typography>
      </Box>
      <Box sx={{ paddingTop: 4, paddingBottom: 2 }}>
        <Typography
          variant="h6"
          sx={{
            color: "#142316",
            fontSize: "25px",
            fontFamily: "Poppins",
            fontWeight: 600,
            lineHeight: "42px",
          }}
        >
          {KeyChallengeTitle}
        </Typography>
        <Box
          sx={{
            borderBottom: 2,
            borderColor: "#f26c05",
            width: "7rem",
          }}
        ></Box>
        <Typography
          variant="subtitle1"
          sx={{
            color: "#030303",
            fontSize: "20px",
            fontFamily: "Poppins",
            lineHeight: "42px",
          }}
        >
          {KeyChallengeOverview}
        </Typography>
      </Box>
      <Box sx={{ paddingTop: 4, paddingBottom: 2 }}>
        <Typography
          variant="h6"
          sx={{
            color: "#142316",
            fontSize: "25px",
            fontFamily: "Poppins",
            fontWeight: 600,
          }}
        >
          {KeyObjectiveTitle}
        </Typography>
        <Box
          sx={{ borderBottom: 2, borderColor: "#f26c05", width: "7rem" }}
        ></Box>
        <Typography
          variant="subtitle1"
          sx={{
            color: "#030303",
            fontSize: "20px",
            fontFamily: "Poppins",
          }}
        >
          {KeyObjectiveOverview}
        </Typography>
      </Box>
    </Box>
  );
};
export default UseCaseDetails;

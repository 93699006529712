import { Avatar, Card, CardContent, Grid, Typography } from "@mui/material";

const JudgeCard = ({ name, title, img, criteria }) => (
  <Grid item xs={12} sm={6} md={3}>
    <Card sx={{ height: "120px", marginBottom: 1, borderRadius: "15px" }}>
      <CardContent>
        <Typography
          variant="body2"
          align="center"
          sx={{ marginBottom: 2, fontSize: "15px", fontWeight: 500 }}
        >
          "{criteria}"
        </Typography>
      </CardContent>
    </Card>

    <Avatar
      alt={name}
      src={img}
      sx={{ width: 100, height: 100, margin: "0 auto" }}
    />
    <Typography variant="h6" align="center" sx={{ marginTop: 2 }}>
      {name}
    </Typography>
    <Typography variant="body2" align="center" color="text.secondary">
      {title}
    </Typography>
  </Grid>
);
export default JudgeCard;

import { Box, Divider, Typography } from "@mui/material";

const HomeLocDateEligibiltyPrizes = ({ isSmallScreen, padding }) => (
  <Box
    sx={{
      width: "auto",
      display: "flex",
      flexDirection: {
        xs: "column",
        md: "row",
      },
      justifyContent: "space-between",
      alignItems: "center",
      padding: "16px",
      border: "1px solid #FFA500",
      borderRadius: "8px",
      backgroundColor: "#F9F9F9",
      "& > div": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "0 16px",
        margin: {
          xs: "8px 0",
          md: "0",
        },
      },
    }}
  >
    <Box
      sx={{
        padding: padding,
      }}
    >
      <Typography variant="body1" fontWeight="bold">
        Location
      </Typography>
      <Typography variant="body2">India</Typography>
    </Box>
    <Divider
      orientation={isSmallScreen ? "horizontal" : "vertical"}
      flexItem
      sx={{
        borderColor: "#FFA500",
      }}
      variant={isSmallScreen ? "fullWidth" : "middle"}
    />
    <Box>
      <Typography variant="body1" fontWeight="bold">
        Date
      </Typography>
      <Typography variant="body2">01/07/2024 - 23/07/2024</Typography>
    </Box>
    <Divider
      orientation={isSmallScreen ? "horizontal" : "vertical"}
      flexItem
      sx={{
        borderColor: "#FFA500",
      }}
      variant={isSmallScreen ? "fullWidth" : "middle"}
    />
    <Box>
      <Typography variant="body1" fontWeight="bold">
        Eligibility
      </Typography>
      <Typography variant="body2">DigiTech & AI Works</Typography>
    </Box>
    <Divider
      orientation={isSmallScreen ? "horizontal" : "vertical"}
      variant={isSmallScreen ? "fullWidth" : "middle"}
      flexItem
      sx={{
        borderColor: "#FFA500",
      }}
    />
    <Box>
      <Typography variant="body1" fontWeight="bold">
        Prizes
      </Typography>
      <Typography variant="body2">Rewards worth Rs 1 Lac+</Typography>
    </Box>
  </Box>
);

export default HomeLocDateEligibiltyPrizes;

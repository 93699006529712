import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import { PrizeCard } from "./PrizesCard";
import { prizes } from "../../constant/Prizes";
const Prizes = () => {
  return (
    <Container sx={{ marginTop: 4 }}>
      <Typography
        variant="h6"
        align="center"
        sx={{ marginBottom: 1, fontWeight: "bold", fontSize: "40px" }}
      >
        Prizes
      </Typography>
      <Typography variant="subtitle1" align="center" sx={{ marginBottom: 4 }}>
        Know what's in it for you
      </Typography>
      <Grid container spacing={2} ml={6} justifyContent="center">
        {prizes.map((prize, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <PrizeCard {...prize} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Prizes;

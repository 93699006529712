import { image } from "../constant/image";
import { stringStr } from "./String";
export const useCases = [
  {
    icon: image.umbrella,
    Title: stringStr.UseCasesMenu.usesCasesCardStrGenzEngagement,
    TitleOverview: stringStr.UseCasesCardContent.GenzEngagement.TitleOverview,

    BusinessContext: {
      Title: stringStr.UseCasesCardContent.GenzEngagement.BusinessContext.Title,

      Overview:
        stringStr.UseCasesCardContent.GenzEngagement.BusinessContext
          .TitleSubTitle,
    },
    KeyChallenge: {
      Title: stringStr.UseCasesCardContent.GenzEngagement.KeyChallenge.Title,
      Overview:
        stringStr.UseCasesCardContent.GenzEngagement.KeyChallenge.TitleSubTitle,
    },
    KeyObjective: {
      Title: stringStr.UseCasesCardContent.GenzEngagement.KeyObjectives.Title,
      Overview:
        stringStr.UseCasesCardContent.GenzEngagement.KeyObjectives
          .TitleSubTitle,
    },
    highlighted: 0,
  },
  {
    icon: image.compound,
    Title: stringStr.UseCasesMenu.usesCasesCardStrHNIUp_SellAndCross,
    TitleOverview:
      stringStr.UseCasesCardContent.HNIUpSellAndCrossSell.TitleOverview,
    BusinessContext: {
      Title:
        stringStr.UseCasesCardContent.HNIUpSellAndCrossSell.BusinessContext
          .Title,

      Overview:
        stringStr.UseCasesCardContent.HNIUpSellAndCrossSell.BusinessContext
          .TitleSubTitle,
    },
    KeyChallenge: {
      Title:
        stringStr.UseCasesCardContent.HNIUpSellAndCrossSell.KeyChallenge.Title,
      Overview:
        stringStr.UseCasesCardContent.HNIUpSellAndCrossSell.KeyChallenge
          .TitleSubTitle,
    },
    KeyObjective: {
      Title:
        stringStr.UseCasesCardContent.HNIUpSellAndCrossSell.KeyObjectives.Title,
      Overview:
        stringStr.UseCasesCardContent.HNIUpSellAndCrossSell.KeyObjectives
          .TitleSubTitle,
    },
    highlighted: 1,
  },
  {
    icon: image.kitesurfing,
    Title: stringStr.UseCasesMenu.usesCasesCardStrFinancialAdPortal,
    TitleOverview:
      stringStr.UseCasesCardContent.FinancialAdvisoryPortal.TitleOverview,
    BusinessContext: {
      Title:
        stringStr.UseCasesCardContent.FinancialAdvisoryPortal.BusinessContext
          .Title,

      Overview:
        stringStr.UseCasesCardContent.FinancialAdvisoryPortal.BusinessContext
          .TitleSubTitle,
    },
    KeyChallenge: {
      Title:
        stringStr.UseCasesCardContent.FinancialAdvisoryPortal.KeyChallenge
          .Title,
      Overview:
        stringStr.UseCasesCardContent.FinancialAdvisoryPortal.KeyChallenge
          .TitleSubTitle,
    },
    KeyObjective: {
      Title:
        stringStr.UseCasesCardContent.FinancialAdvisoryPortal.KeyObjectives
          .Title,
      Overview:
        stringStr.UseCasesCardContent.FinancialAdvisoryPortal.KeyObjectives
          .TitleSubTitle,
    },
    highlighted: 2,
  },
  {
    icon: image.umbrella,
    Title: stringStr.UseCasesMenu.usesCasesCardStrGenzEngagement,
    TitleOverview: stringStr.UseCasesCardContent.GenzEngagement.TitleOverview,
    BusinessContext: {
      Title: stringStr.UseCasesCardContent.GenzEngagement.BusinessContext.Title,

      Overview:
        stringStr.UseCasesCardContent.GenzEngagement.BusinessContext
          .TitleSubTitle,
    },
    KeyChallenge: {
      Title: stringStr.UseCasesCardContent.GenzEngagement.KeyChallenge.Title,
      Overview:
        stringStr.UseCasesCardContent.GenzEngagement.KeyChallenge.TitleSubTitle,
    },
    KeyObjective: {
      Title: stringStr.UseCasesCardContent.GenzEngagement.KeyObjectives.Title,
      Overview:
        stringStr.UseCasesCardContent.GenzEngagement.KeyObjectives
          .TitleSubTitle,
    },
    highlighted: 3,
  },
  {
    icon: image.directioBoat,
    Title: stringStr.UseCasesMenu.usesCasesCardStrWhatsappJourneyEnablement,
    TitleOverview:
      stringStr.UseCasesCardContent.WhatsappJourneyEnablement.TitleOverview,
    BusinessContext: {
      Title:
        stringStr.UseCasesCardContent.WhatsappJourneyEnablement.BusinessContext
          .Title,

      Overview:
        stringStr.UseCasesCardContent.WhatsappJourneyEnablement.BusinessContext
          .TitleSubTitle,
    },
    KeyChallenge: {
      Title:
        stringStr.UseCasesCardContent.WhatsappJourneyEnablement.KeyChallenge
          .Title,
      Overview:
        stringStr.UseCasesCardContent.WhatsappJourneyEnablement.KeyChallenge
          .TitleSubTitle,
    },
    KeyObjective: {
      Title:
        stringStr.UseCasesCardContent.WhatsappJourneyEnablement.KeyObjectives
          .Title,
      Overview:
        stringStr.UseCasesCardContent.WhatsappJourneyEnablement.KeyObjectives
          .TitleSubTitle,
    },
    highlighted: 4,
  },
  {
    icon: image.rocketLaunch,
    Title: stringStr.UseCasesMenu.usesCasesCardStrEnhancedUnderWritingIsuuance,
    TitleOverview:
      stringStr.UseCasesCardContent.EnhancedUnderwritingAndIssurance
        .TitleOverview,
    BusinessContext: {
      Title:
        stringStr.UseCasesCardContent.EnhancedUnderwritingAndIssurance
          .BusinessContext.Title,

      Overview:
        stringStr.UseCasesCardContent.EnhancedUnderwritingAndIssurance
          .BusinessContext.TitleSubTitle,
    },
    KeyChallenge: {
      Title:
        stringStr.UseCasesCardContent.EnhancedUnderwritingAndIssurance
          .KeyChallenge.Title,
      Overview:
        stringStr.UseCasesCardContent.EnhancedUnderwritingAndIssurance
          .KeyChallenge.TitleSubTitle,
    },
    KeyObjective: {
      Title:
        stringStr.UseCasesCardContent.EnhancedUnderwritingAndIssurance
          .KeyObjectives.Title,
      Overview:
        stringStr.UseCasesCardContent.EnhancedUnderwritingAndIssurance
          .KeyObjectives.TitleSubTitle,
    },
    highlighted: 5,
  },
  {
    icon: image.terrain,
    Title: stringStr.UseCasesMenu.usesCasesCardStrRetirmentBusinessGrowth,
    TitleOverview:
      stringStr.UseCasesCardContent.RetirmentBusinessGrowth.TitleOverview,
    BusinessContext: {
      Title:
        stringStr.UseCasesCardContent.RetirmentBusinessGrowth.BusinessContext
          .Title,

      Overview:
        stringStr.UseCasesCardContent.RetirmentBusinessGrowth.BusinessContext
          .TitleSubTitle,
    },
    KeyChallenge: {
      Title:
        stringStr.UseCasesCardContent.RetirmentBusinessGrowth.KeyChallenge
          .Title,
      Overview:
        stringStr.UseCasesCardContent.RetirmentBusinessGrowth.KeyChallenge
          .TitleSubTitle,
    },
    KeyObjective: {
      Title:
        stringStr.UseCasesCardContent.RetirmentBusinessGrowth.KeyObjectives
          .Title,
      Overview:
        stringStr.UseCasesCardContent.RetirmentBusinessGrowth.KeyObjectives
          .TitleSubTitle,
    },
    highlighted: 6,
  },
  {
    icon: image.directioBoat,
    Title: stringStr.UseCasesMenu.usesCasesCardStrWhatsappJourneyEnablement,
    TitleOverview:
      stringStr.UseCasesCardContent.WhatsappJourneyEnablement.TitleOverview,
    BusinessContext: {
      Title:
        stringStr.UseCasesCardContent.WhatsappJourneyEnablement.BusinessContext
          .Title,

      Overview:
        stringStr.UseCasesCardContent.WhatsappJourneyEnablement.BusinessContext
          .TitleSubTitle,
    },
    KeyChallenge: {
      Title:
        stringStr.UseCasesCardContent.WhatsappJourneyEnablement.KeyChallenge
          .Title,
      Overview:
        stringStr.UseCasesCardContent.WhatsappJourneyEnablement.KeyChallenge
          .TitleSubTitle,
    },
    KeyObjective: {
      Title:
        stringStr.UseCasesCardContent.WhatsappJourneyEnablement.KeyObjectives
          .Title,
      Overview:
        stringStr.UseCasesCardContent.WhatsappJourneyEnablement.KeyObjectives
          .TitleSubTitle,
    },
    highlighted: 7,
  },
];

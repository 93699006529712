import { Box, Card, CardContent, Typography } from "@mui/material";
import medalIcon from "../../assets/Iconmedal.png";
import CustomeImage from "../comman/CustomeImage";
export const PrizeCard = ({ place, amount, color }) => {
  return (
    <Card
      sx={{
        backgroundColor: color,
        borderRadius: "16px",
        width: "60%",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ height: "7rem" }}>
          <CustomeImage src={medalIcon} alt={"Prizes"}></CustomeImage>
        </Box>

        <Typography
          variant="h5"
          component="div"
          sx={{
            fontWeight: 600,
            marginTop: 1,
            textAlign: "center",
            fontSize: "30px",
          }}
        >
          {place}
        </Typography>
        <Typography variant="h6" sx={{ marginTop: 1 }}>
          {amount}
        </Typography>
      </CardContent>
    </Card>
  );
};

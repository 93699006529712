import { Box, Button, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const QuestionComponent = ({ question, onButtonClickHandler }) => {
  return (
    <Box sx={{ marginY: { xs: 1, md: 2 } }}>
      <Button
        fullWidth
        sx={{
          display: "flex",
          justifyContent: "space-between",
          color: "#000000",
          padding: { xs: "5px", md: "10px" },
          fontSize: { xs: "0.5rem", md: "1rem" },
          textTransform: "capitalize",
        }}
        onClick={onButtonClickHandler}
      >
        <Typography
          sx={{
            fontSize: { xs: "0.625rem", md: "1rem" },
            fontWeight: "bold",
          }}
        >
          {question}
        </Typography>

        <KeyboardArrowDownIcon
          sx={{ fontSize: { xs: "1.2rem", md: "1.5rem" } }}
        />
      </Button>
    </Box>
  );
};
export default QuestionComponent;

import { stringStr } from "../../constant/String";

export const Validation = (formData) => {
  const error = {};

  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  if (!formData.TeamName || typeof formData.TeamName !== "string") {
    error.TeamName = stringStr.error.teamName;
  }

  if (!formData.Usecase || typeof formData.Usecase !== "string") {
    error.Usecase = stringStr.error.useCase;
  }
  error.Members = formData.Members.map((member, index) => {
    const memberError = {};
    if (
      index < 3 ||
      member.MemberName ||
      member.MemberEmail ||
      member.MemberRole
    ) {
      if (!member.MemberName || typeof formData.Usecase !== "string") {
        memberError.MemberName = stringStr.error.name;
      }
      if (!member.MemberEmail || typeof formData.Usecase !== "string") {
        memberError.MemberEmail = stringStr.error.email;
      } else if (!emailRegex.test(member.MemberEmail)) {
        memberError.MemberEmail = stringStr.error.invalidEmail;
      }
      if (!member.MemberRole || typeof formData.Usecase !== "string") {
        memberError.MemberRole = stringStr.error.role;
      }

      return memberError;
    }
  });

  return error;
};

import { Box, Card, Stack, Typography } from "@mui/material";
import { stringStr } from "../../constant/String";
import { HeaderEventTypography } from "../comman/HeaderEventTypography";
import { BoxesForDetails } from "../comman/BoxesForDetails";

const boxesForDetails = [
  {
    content1: {
      border: false,
      content1: stringStr.EventDetailsMethodlogy.conetntLine1.content1,
      content2: stringStr.EventDetailsMethodlogy.conetntLine1.content2,
      content3: stringStr.EventDetailsMethodlogy.conetntLine1.content3,
    },
  },
  {
    content2: {
      border: true,
      content1: stringStr.EventDetailsMethodlogy.conetntLine2.content1,
      content2: stringStr.EventDetailsMethodlogy.conetntLine2.content2,
      content3: stringStr.EventDetailsMethodlogy.conetntLine2.content3,
    },
  },
];
const EventMethodology = () => {
  return (
    <Box
      sx={{
        textAlign: "center",
        justifyContent: "center",
        padding: 4,
        marginTop: "20px",
      }}
    >
      <Typography variant="h4" sx={{}}>
        Event Timelines
      </Typography>
      <Typography variant="subtitle1" sx={{ padding: 2 }}>
        {" "}
        A detailed view of all event-related timelines
      </Typography>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#ffffff",
          borderRadius: "24px",
          border: "1px solid #4f4f4f",
          boxSizing: "border-box",
        }}
      >
        <Card
          sx={{
            top: "3846px",
            left: "74px",
            width: "auto",
            height: "60px",
            backgroundColor: "#f26c05",
            borderRadius: "24px",
            display: "flex",
            padding: 2,
            alignContent: "center",
            justifyContent: "space-between",
          }}
        >
          <HeaderEventTypography title={"Task"} />
          <HeaderEventTypography title={"Details"} />
          <HeaderEventTypography title={"Date"} />
        </Card>
        <Stack sx={{}}>
          <BoxesForDetails
            border={false}
            content1={stringStr.EventDetailsMethodlogy.conetntLine1.content1}
            content2={stringStr.EventDetailsMethodlogy.conetntLine1.content2}
            content3={stringStr.EventDetailsMethodlogy.conetntLine1.content3}
          />
          <BoxesForDetails
            border={true}
            content1={stringStr.EventDetailsMethodlogy.conetntLine2.content1}
            content2={stringStr.EventDetailsMethodlogy.conetntLine2.content2}
            content3={stringStr.EventDetailsMethodlogy.conetntLine2.content3}
          />
          <BoxesForDetails
            border={true}
            content1={stringStr.EventDetailsMethodlogy.conetntLine3.content1}
            content2={stringStr.EventDetailsMethodlogy.conetntLine3.content2}
            content3={stringStr.EventDetailsMethodlogy.conetntLine3.content3}
          />
          <BoxesForDetails
            border={true}
            content1={stringStr.EventDetailsMethodlogy.conetntLine4.content1}
            content2={stringStr.EventDetailsMethodlogy.conetntLine4.content2}
            content3={stringStr.EventDetailsMethodlogy.conetntLine4.content3}
          />
          <BoxesForDetails
            border={true}
            content1={stringStr.EventDetailsMethodlogy.conetntLine5.content1}
            content2={stringStr.EventDetailsMethodlogy.conetntLine5.content2}
            content3={stringStr.EventDetailsMethodlogy.conetntLine5.content3}
          />
          <BoxesForDetails
            border={true}
            content1={stringStr.EventDetailsMethodlogy.conetntLine6.content1}
            content2={stringStr.EventDetailsMethodlogy.conetntLine6.content2}
            content3={stringStr.EventDetailsMethodlogy.conetntLine6.content3}
          />
          <BoxesForDetails
            border={true}
            content1={stringStr.EventDetailsMethodlogy.conetntLine7.content1}
            content2={stringStr.EventDetailsMethodlogy.conetntLine7.content2}
            content3={stringStr.EventDetailsMethodlogy.conetntLine7.content3}
          />
          <BoxesForDetails
            border={true}
            content1={stringStr.EventDetailsMethodlogy.conetntLine8.content1}
            content2={stringStr.EventDetailsMethodlogy.conetntLine8.content2}
            content3={stringStr.EventDetailsMethodlogy.conetntLine8.content3}
          />
        </Stack>
      </Box>
    </Box>
  );
};
export default EventMethodology;

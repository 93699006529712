import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./component/Header/Header";
import Home from "./component/Home/Home";
import Registration from "./component/Registration/Registration";
import Footer from "./component/Footer";

import AboutTheEvent from "./component/EventDetails/AboutTheEvent";
import UseCase from "./component/UseCases/UseCase";
import FAQ from "./component/FAQ/FAQ";
import ThankyouPage from "./component/ThankYou/ThankyouPage";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/usecases" element={<UseCase />} />
        <Route path="/eventdetails" element={<AboutTheEvent />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/thankyou" element={<ThankyouPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;

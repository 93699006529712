import { Box, Card, CardContent, CardHeader } from "@mui/material";

const RegistrationCards = ({ title, children }) => {
  return (
    <Card
      sx={{
        backgroundColor: "#dfd9ce",
        marginLeft: "20px",
        marginRight: "20px",
        borderRadius: "20px",
      }}
    >
      <CardHeader
        title={title}
        sx={{
          backgroundColor: "#f26c05",
          color: "#ffffff",
          alignItems: "start",
          borderRadius: "24px",
        }}
      ></CardHeader>

      <CardContent>
        <Box
          sx={{
            padding: "16px",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          {children}
        </Box>
      </CardContent>
    </Card>
  );
};
export default RegistrationCards;

import { Container, useMediaQuery, useTheme } from "@mui/material";
import UseCases from "./UseCases";
import { stringStr } from "../../constant/String";
import BottamCard from "../comman/BottomCard";
import usePageBottom from "../../Hooks/UsePageBottam";
import { ScrollUpArrow } from "../comman/ScrollUpArrow";

const UseCase = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const showGoTop = usePageBottom();
  return (
    <Container
      maxWidth="lg"
      sx={{
        margin: "0 auto",
        marginTop: "15px",
        padding: isSmallScreen ? "10px" : "20px",
      }}
    >
      <UseCases />

      <BottamCard
        title1={stringStr.ListeningCardUseCase.ListeningCardTitle1}
        title2={stringStr.ListeningCardUseCase.ListeningCardTitle2}
        subTitle={stringStr.ListeningCardUseCase.ListeningCardSubTitle}
        buttonLabel={stringStr.registerNow}
      />
      {showGoTop && <ScrollUpArrow />}
    </Container>
  );
};
export default UseCase;

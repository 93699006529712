import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Phases from "../comman/Phases";
import { phases1, phases2 } from "../../constant/Phase1";
import { stringStr } from "../../constant/String";

const EventTimeLine = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box sx={{ marginTop: "30px", padding: isMobile ? "10px" : "5px" }}>
      <Box>
        <Typography
          sx={{
            color: "#030303",
            fontSize: isMobile ? "28px" : "40px",
            fontWeight: 600,
            lineHeight: isMobile ? "40px" : "62px",
            textAlign: "center",
          }}
        >
          {stringStr.eventDetailTitle}
        </Typography>
        <Typography
          sx={{
            color: "#030303",
            fontSize: isMobile ? "14px" : "17px",
            lineHeight: isMobile ? "22px" : "26px",
            textAlign: "center",
            margin: isMobile ? "10px" : "15px",
          }}
        >
          {stringStr.eventDetailSubtitle}
        </Typography>
      </Box>
      <Box sx={{ mt: isMobile ? "20px" : "30px" }}>
        <Phases data={phases1} title={stringStr.phase1Str} />
        <Phases data={phases2} title={stringStr.phase2Str} />
      </Box>
    </Box>
  );
};
export default EventTimeLine;

import { Button } from "@mui/material";
import { stringStr } from "../../constant/String";

const RegisterButton = ({ onClickHandler }) => (
  <Button
    variant="contained"
    sx={{
      backgroundColor: "#f26c05",
      textTransform: "capitalize",
      fontWeight: 600,
      boxSizing: "border-box",
      borderRadius: "12px",
      width: { md: "50%" },
      marginBottom: {
        xs: "10px",
        md: "0",
      },
    }}
    onClick={onClickHandler}
  >
    {stringStr.registerNow}
  </Button>
);
export default RegisterButton;

import { Box, Button, IconButton, Menu, MenuItem } from "@mui/material";
import { stringStr } from "../../constant/String";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

const SmallScreenHeader = (props) => (
  <Box
    sx={{
      flexGrow: 1,
      display: {
        xs: "flex",
        md: "none",
      },
    }}
  >
    <IconButton
      size="large"
      aria-label="account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={props.handleOpenNavMenu}
      color="inherit"
    >
      <MenuIcon />
    </IconButton>
    <Menu
      id="menu-appbar"
      anchorEl={props.anchorElNav}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={Boolean(props.anchorElNav)}
      onClose={props.handleCloseNavMenu}
      sx={{
        display: {
          xs: "block",
          md: "none",
        },
        padding: 2,
      }}
    >
      {props.MenuItems.map((item, index) => (
        <MenuItem onClick={props.handleCloseNavMenu} key={index}>
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Link
              to={item.path}
              style={{
                textDecoration: "none",
                color: props.activeMenu === item.value ? "#f26c05" : "inherit",
                padding: "0.5rem 1 rem",
                width: "100%",
                display: "block",
                fontWeight: 600,
              }}
              onClick={() => {
                props.handlerMenuClick(item.value);
              }}
            >
              {item.label}
            </Link>
          </Box>
        </MenuItem>
      ))}

      <Box sx={{}}>
        <Button
          sx={{
            color: "#ffffff",
            backgroundColor: "#f26c05",
            alignContent: "center",
            margin: "0 0.6rem 0 0.6rem",
            textTransform: "capitalize",
            fontWeight: 600,
          }}
          variant="contained"
          onClick={props.onSubmitHandler}
        >
          {stringStr.registerNow}
        </Button>
      </Box>
    </Menu>
  </Box>
);

export default SmallScreenHeader;
